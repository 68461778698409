<template>
  <span v-if="user">
    <editable-page
      v-if="profile"
      :title="profile && profile.getHandle() ? profile.getHandle() : name"
      :imageUrl="profile.getProfile() && profile.getProfile().getImageUrl()"
      :apiStatusList="[getProfileStatus]"
      :edit="true"
      loginRequired
    >
      <template v-slot:subtitle v-if="hasSubtitle">
        <v-row class="pl-5">
          <v-col>
            <stats-col
              title="Joined"
              :cols="6"
              :md="3"
              :lg="2"
              class="font-weight-medium"
            >
              {{ profile && formatDate(profile.getJoinedOn()) }}
            </stats-col>
          </v-col>
          <!--   <v-col>
          <stats-col title="Problem Solved" :cols="6" :md="3" :lg="2" class="font-weight-medium">
            {{ profile && profile.getProblemSolved() }}
          </stats-col>
        </v-col> -->
          <!--  <v-col>
          <stats-col title="Rating" :cols="6" :md="3" :lg="2" class="font-weight-medium">
            {{ profile && profile.getRating() }}
          </stats-col>
        </v-col> -->
          <v-col :offset="6">
            <stats-col title="">
              <v-btn class="mb-1" color="accent" @click="profileEditClose()"
                >Back</v-btn
              >
            </stats-col>
          </v-col>
        </v-row>
      </template>
      <error-message :ex="ex"></error-message>

      <v-tabs>
        <v-tab>Personal</v-tab>
        <v-tab>Competitive</v-tab>
        <!-- <v-tab>Career</v-tab> -->
        <v-tab-item>
          <personal-profile
            :profile="profile"
            :edit="true"
            @changeHandle="saveHandle"
            @changeName="saveName"
            @save="save"
            :isContentSaved="isPersonalProfileSaved"
          >
          </personal-profile>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col>
              <competitive-profile
                :competitiveProfiles="
                  profile &&
                  profile.getProfile() &&
                  profile.getProfile().getCompetitiveProfilesList()
                "
                @addCPProfile="saveCPProfile"
                @removeProfile="removeCPSite"
                @verifyProfile="verify"
                :edit="true"
                :isContentVerified="isHandleVerified"
                :isContentDeleted="isHandleDeleted"
              >
              </competitive-profile>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card flat outlined>
                <v-card-title>Contests</v-card-title>
                <v-data-table
                  class="px-5"
                  :headers="headers"
                  :items="
                    profile.getProfile() &&
                    profile.getProfile().getContestStatsList()
                  "
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        <router-link
                          v-if="item.getContentPreview()"
                          :to="'/contests/' + item.getContentPreview().getUrl()"
                        >
                          {{ item.getContentPreview().getTitle() }}
                        </router-link>
                        <span v-else> Unknown Contest </span>
                      </td>
                      <td>
                        <router-link
                          v-if="item.getContentPreview()"
                          :to="
                            '/contests/' +
                            item.getContentPreview().getUrl() +
                            '/scoreboard'
                          "
                        >
                          {{ item.getRank() }}
                        </router-link>
                        <span v-else> {{ item.getRank() }}</span>
                      </td>
                      <td>{{ item.getProblemSolved() }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- <v-tab-item>
				<v-row>
					<v-col>
						<v-card flat outlined>
							<v-row>
								<v-col>
									<v-card-title>About </v-card-title>
								</v-col>
								<v-col class="text-right">
									<v-dialog v-model="editDone" @keydown.esc="editDone = false">
										<template v-slot:activator="{on, attrs}">
											<v-btn icon v-bind="attrs" v-on="on">
												<img :src="require('./../assets/images/icon/Edit.svg')" />
											</v-btn>
										</template>
										<v-card>
											<v-card-title>About</v-card-title>
											<v-card-text>
												<v-textarea label="About" :value="profile && profile.getProfile() && profile.getProfile().getAbout()" counter maxlength="120" full-width single-line @change="saveAbout" >
												</v-textarea>
											</v-card-text>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn color="accent" text @click="saveProfile">Save</v-btn>
												<v-btn color="accent" text @click="editDone = false">CANCEL</v-btn>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="mt-n10">
									<v-card-text>{{profile && profile.getProfile() && profile.getProfile().getAbout()}}</v-card-text>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<experience-card  :experiences="profile && profile.getProfile() && profile.getProfile().getCareer() && profile.getProfile().getCareer().getExperiencesList()"
						@change="saveExperience"
						@updateTitle="editTitle"
						@updateCompany="editCompany"
						@updateLocation="editLocation"
						@updateProfile="saveProfile"
						>
						</experience-card>
					</v-col>
				</v-row>
			</v-tab-item> -->
      </v-tabs>
    </editable-page>
  </span>
  <span v-else>
    <v-row class="pa-10">
      <v-col>
        Please <router-link to="/community/login"> login </router-link> to edit.
      </v-col>
    </v-row>
  </span>
</template>
<script>
import EditablePage from '../components/EditablePage'
import ExperienceCard from '../components/ExperienceCard'
import { mapActions, mapState, mapMutations } from 'vuex'
import CompetitiveProfile from '../components/CompetitiveProfile'
import StatsCol from '../components/StatsCol'
import PersonalProfile from '../components/PersonalProfile'
import ErrorMessage from '../components/ErrorMessage.vue'

export default {
  props: {
    handle: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      editDone: false,
      isHandleVerified: false,
      isHandleDeleted: false,
      isPersonalProfileSaved: false,
      headers: [
        {
          text: 'Title',
          align: 'left',
        },
        {
          text: 'Rank',
          align: 'left',
        },
        {
          text: 'Problem Solved',
          align: 'left',
        },
      ],
      ex: null,
    }
  },
  methods: {
    ...mapActions('user', [
      'getProfile',
      'updateProfile',
      'editProfile',
      'verifyCpProfile',
    ]),
    ...mapMutations('user', [
      'setAbout',
      'setHandle',
      'addExperience',
      'updateTitle',
      'updateCompany',
      'updateLocation',
      'updateCPProfile',
      'removeCPProfile',
      'setName',
    ]),
    profileEditClose() {
      this.$router.push('/profile/')
    },
    saveAbout(introduction) {
      this.setAbout(introduction)
    },
    saveExperience(experience) {
      this.addExperience(experience)
      //this.updateProfile().then(__ => this.editDone = false);
    },
    editTitle(title, index) {
      this.updateTitle({ title: title, index: index })
    },
    editCompany(company, index) {
      this.updateCompany({ company: company, index: index })
    },
    editLocation(location, index) {
      this.updateLocation({ location: location, index: index })
    },
    saveProfile() {
      //this.updateProfile().then(__ => this.editDone = false);
    },
    saveCPProfile(cpProfile) {
      this.updateCPProfile(cpProfile)
      this.save()
    },
    removeCPSite(i) {
      this.isHandleDeleted = true
      this.removeCPProfile(i)
      this.save()
      this.isHandleDeleted = false
    },
    saveHandle(userHandle) {
      this.setHandle(userHandle)
    },
    saveName(name) {
      this.setName(name)
    },
    save() {
      this.isPersonalProfileSaved = true
      this.editProfile()
        .then((__) => (this.editDone = false))
        .catch((ex) => (this.ex = ex))
        .finally((__) => (this.isPersonalProfileSaved = false))
    },
    verify(site) {
      this.isHandleVerified = true
      this.verifyCpProfile(site)
        .catch((ex) => (this.ex = ex))
        .finally((__) => (this.isHandleVerified = false))
    },
    formatDate(date) {
      var d = new Date(date)
      var dd = String(d.getDate()).padStart(2, '0')
      var yyyy = d.getFullYear()
      var mmm = d.toLocaleString('default', { month: 'short' })
      d = dd + ' ' + mmm + ' ' + yyyy
      return d
    },
  },
  computed: {
    ...mapState('user', ['profile', 'getProfileStatus', 'user']),
    hasSubtitle() {
      return true
    },
    name() {
      console.log('profile ', this.profile)
      if (this.profile) return this.profile.getName()
    },
  },
  async mounted() {
    console.log('user', this.user)
    if (this.user) this.getProfile()
  },
  watch: {
    profile(p) {
      console.log('watch profile', p)
    },
  },
  components: {
    EditablePage,
    ExperienceCard,
    CompetitiveProfile,
    StatsCol,
    PersonalProfile,
    ErrorMessage,
  },
}
</script>
